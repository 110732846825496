.tab-container {
  width: 100%;
}

.tab-header {
  display: flex;
  border-bottom: 1px solid #282828;
  padding: 0 50px;
  gap: 25px;
  flex-wrap: wrap;
}

.tab-content {
  padding: 20px;
  background-color: #1c1c1c;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 22px;
  opacity: 0.5;
  color: #fff;
}

.tab-item.active {
  border-bottom: 5px solid #fff;
  opacity: 1;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

@media (max-width: 999px) {
  .tab-header {
    gap: 10px;
  }
  .tab-item {
    font-size: 18px;
    padding: 5px 10px;
  }
}