table {
  width: 100%;
  border-collapse: collapse;
}
th {
  border-bottom: 1px solid #ffffff1a;
  padding: 8px;
  text-align: left;
}
td {
  padding: 8px;
  text-align: left;
  color: #b6b6b6;
  font-size: 12px;
  font-weight: 400px;
}
.promocode-btn {
  margin-left: 0;
}
.btns {
  display: flex;
  gap: 10px;
}
.persona-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.persona-links a:visited,
.persona-links a:link {
  color: #b6b6b6;
}
.expired *,
.expired .persona-links a:visited,
.expired .persona-links a:link {
  color: #b17c00;
}
