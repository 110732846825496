.large-button {
  background-color: #f0ede0; /* Light beige background */
  color: black; /* Black text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding around text and icon */
  font-size: 16px; /* Text size */
  display: inline-flex; /* For aligning icon and text */
  align-items: center; /* Vertical alignment */
  font-weight: 600;
  justify-content: center; /* Horizontal alignment */
  height: 50px; /* Fixed height */
  cursor: pointer; /* Pointer cursor on hover */
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.large-button-fillwidth {
  width: 100% !important;
}
.button-icon {
  height: 24px; /* Height of the icon */
  width: 24px; /* Width of the icon */
  margin-right: 10px; /* Space between icon and text */
}
.large-button-content{
  display: flex;
  align-items: center;
}

.large-button-outline {
  background-color: transparent;
  color: gray; /* Black text */
  border: 1px solid gray;
  padding: 10px 20px; /* Padding around text and icon */
  font-size: 16px; /* Text size */
  display: inline-flex; /* For aligning icon and text */
  align-items: center; /* Vertical alignment */
  font-weight: 600;
  justify-content: center; /* Horizontal alignment */
  height: 50px; /* Fixed height */
  cursor: pointer; /* Pointer cursor on hover */
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.large-button:disabled {
  background-color: #f0ede0 !important; /* Light beige background */
  color: gray; /* Black text */
  cursor: not-allowed; /* Pointer cursor on hover */
}

.large-button.loading .large-button-content {
  opacity: 0;
}
.large-button .loading, .large-button-outline .loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  color: #000;
}
.large-button.loading .loading, .large-button-outline.loading .loading {
  opacity: 1;
}
.large-button.loading .loading span, .large-button-outline.loading .loading span {
  /* animate 3 dots*/
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-2px);
  }
  40% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
