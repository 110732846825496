.collapsible {
  /* ...existing code... */
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
  margin-top: 10px;
}

.collapsible-content.open {
  max-height: 1000px; /* Arbitrary large value to ensure it can expand */
}

.collapsible-button {
  background-color: transparent;
  padding: 5px 6px;
  border: 1px solid gray;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  font-family: "Inter";
  cursor: pointer;
  color: #9f9f9f;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible-button:hover {
  background-color: #f0ede033;
}

.dropdown-icon {
  margin-left: 5px;
  font-size: 12px;
}
.dropdown-icon.open {
  transform: rotate(180deg);
}
